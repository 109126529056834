<template>
  <div class="activityDetails">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/eventServices/index' }">活动服务</el-breadcrumb-item>
      <el-breadcrumb-item>活动详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="details">
      <div class="details-top">
        <div class="flex top-head">
          <img :src="details.activityImg" alt="" />
          <div>
            <div ref="activityName">
              <span v-if="nameHight">{{ details.activityName }}</span>
              <el-tooltip v-else :content="details.activityName" placement="top-start" effect="dark" popper-class="atooltip">
                <span>{{ details.activityName }}</span>
              </el-tooltip>
            </div>
            <div>
              <i class="el-icon-time"></i>
              <span>活动时间：<span>{{ timeIntercept(details.activityStartTime,true) }}至{{
                    timeIntercept(details.activityEndTime,true)
                  }}</span></span>
            </div>
            <div>
              <i class="el-icon-location-outline"></i>
              <span>活动地点：<span>{{ details.activityAdress }}</span></span>
            </div>
            <!-- <div>
              <i class="el-icon-wallet"></i>
              <span
                >活动费用：<span style="color: #f93f3f">￥1800.00</span></span
              >
            </div> -->
          </div>
        </div>
        <div class="flex between top-application">
          <ul class="flex between">
            <li>
              <div>
                报名开始时间：{{ timeIntercept(details.applicationStartTime) }}
              </div>
              <div>
                报名截止时间：{{ timeIntercept(details.applicationEndTime) }}
              </div>
            </li>
          </ul>
          <div class="flex">
            <span v-if="details.countdownFlg">距离报名截止<span>{{
                applicationTime(TimeInterval(details.countdown))
              }}</span></span>
            <div v-if="details.countdownFlg && details.activityFlg" @click="handleRegOrCancle" :class="{'registed': isReg} ">
              {{ isReg ? '取消报名' : '立即报名' }}
            </div>
            <div v-else style="background-color: #ccc">
              {{ !details.activityFlg ? "活动结束" : "报名结束" }}
            </div>
            <!-- <div v-else style="background-color: #ccc">已经报名</div> -->
          </div>
        </div>
      </div>
      <div style="font-size: 20px; font-weight: 600; margin-bottom: 20px">
        活动详情
      </div>
      <div class="content">
        <div class="content-left">
          <div v-html="details.activityContent" class="width_content ql-editor"></div>
        </div>
      </div>
      <div class="details-recommend">
        <div>更多推荐</div>
        <activityList v-if="activityList.length > 0" :activityList="activityList" :paginationFlg="false"></activityList>
        <div class="none-data" v-else>
          <img src="@/assets/pc/images/personal/none.png" alt="" />
          <span style="font-size: 14px; color: #b0b0b0">暂无内容</span>
        </div>
      </div>
    </div>
    <permission v-if="dialogVisiblePermission" :dialogVisiblePermission="dialogVisiblePermission" @handleToggle="handleToggle" :typeDialog="typeDialog" @handlePermission="handlePermission">
    </permission>
    <el-dialog title="绑定主体" :visible.sync="dialogVisible" width="500px">
      <el-form ref="form" :model="form" class="form" :rules="rule" label-position="top">
        <el-form-item prop="name" label="主体名称">
          <el-autocomplete class="inline-input" v-model="form.name" :fetch-suggestions="querySearch" placeholder="请输入机构执照名称" :trigger-on-focus="false" @select="handleSelect" :debounce="0" style="width: 100%" clearable>
          </el-autocomplete>
        </el-form-item>
        <el-form-item :label="form.label" v-if="form.type == '0'">
          <div class="creditCode">
            <div style="flex: 1">{{ form.code }}</div>
            <div class="div-code">
              <el-input v-model="form.first_code" maxlength="1" ref="inputRef1"></el-input>
            </div>
            <div class="div-code">
              <el-input v-model="form.second_code" maxlength="1" ref="inputRef2"></el-input>
            </div>
            <div class="div-code">
              <el-input v-model="form.third_code" maxlength="1" ref="inputRef3"></el-input>
            </div>
            <div class="div-code">
              <el-input v-model="form.fourth_code" maxlength="1" ref="inputRef4"></el-input>
            </div>
          </div>
        </el-form-item>
        <el-form-item :label="form.label" v-else>
          <el-input v-model="form.codeInput" placeholder="输入18位统一社会信用代码" maxlength="18"></el-input>
        </el-form-item>
      </el-form>
      <div class="bind-btn-box" style="text-align: center">
        <button class="bind-btn" style="width: 280px" @click="bindEntity">
          立即绑定
        </button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import activityList from "./components/activityList";
import { request } from "@/network";
import { mapState, mapMutations } from "vuex";
import { getMemberId, setInfoData } from "@/assets/public/utils/token";
import permission from "@/components/pc/permission.vue";
export default {
  components: {
    activityList,
    permission,
  },
  data() {
    return {
      details: {},
      content: "",
      activityList: [],
      dialogVisiblePermission: false,
      typeDialog: "",
      dialogVisible: false,
      form: {
        name: "",
        code: "",
        first_code: "",
        second_code: "",
        third_code: "",
        fourth_code: "",
        label: "统一社会信用代码",
        type: "1",
        codeInput: "",
        creditCode: "",
        area: "",
        estiblishTime: "",
        regCapital: "",
        legalPersonName: "",
      },
      rule: {
        name: [
          { required: true, message: "请输入机构执照全称", trigger: "blur" },
        ],
      },
      timer: null,
      nameHight: true,
      activityListId: [],
      isReg: undefined
    };
  },
  computed: {
    dataUser (){
      return localStorage.getItem('DATA_USER');
    }
  },
  watch: {
    "form.name"(val) {
      if (!val) {
        this.form.code = "";
        this.form.type = "1";
        this.form.label = "统一社会信用代码";
      }
    },
    "form.first_code"(val) {
      if (val !== "") {
        this.$refs[`inputRef${2}`].focus();
      }
    },
    "form.second_code"(val) {
      if (val !== "") {
        this.$refs[`inputRef${3}`].focus();
      } else {
        this.$refs[`inputRef${1}`].focus();
      }
    },
    "form.third_code"(val) {
      if (val !== "") {
        this.$refs[`inputRef${4}`].focus();
      } else {
        this.$refs[`inputRef${2}`].focus();
      }
    },
    "form.fourth_code"(val) {
      if (val === "") {
        this.$refs[`inputRef${3}`].focus();
      }
    },
    "$route.query.clickId"(val) {
      this.getDetails(val);
    },
  },
  mounted() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    this.getDetails(this.$route.query.clickId);
    this.getAccountInformation();
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    async getDetails(id) {
      const { data: res } = await request({
        method: "GET",
        url: "/pcp/activity/getActivityInfo",
        params: {
          memberId: getMemberId(),
          id,
        },
      });
      if (res.code !== 200) return this.$message.error("信息获取失败");
      this.isReg = (this.dataUser && res.data.activity.isApplication) ? true : false;
      res.data.activity.countdown = Date.parse(
        new Date(res.data.activity.applicationEndTime)
      );
      if (res.data.activity.countdown - parseInt(new Date().getTime()) > 0) {
        res.data.activity.countdownFlg = true;
        this.countdownTime();
      } else {
        res.data.activity.countdownFlg = false;
      }
      res.data.activity.activityFlg =
        Date.parse(new Date(res.data.activity.activityEndTime)) -
          parseInt(new Date().getTime()) >
          0
          ? true
          : false;
      --res.data.activity.countdown;
      this.details = res.data.activity;
      this.activityList = res.data.moreActivity;
      this.$nextTick(() => {
        this.nameHight = this.$refs.activityName.offsetHeight > 30 ? false : true
      });
    },
    async handleRegisterNow() {
      const { data: res } = await request(
        {
          method: "post",
          url: "/pcp/activity/registerNow",
          data: {
            memberId: getMemberId(),
            activityId: this.details.id,
          },
        },
        true
      );
      if (res.code !== 200) return this.$message.error("获取数据异常");
      this.$message({
        message: "报名成功",
        type: "success",
      });
      this.getDetails(this.$route.query.clickId);
    },
    timeIntercept(time, flg) {
      if (!time) {
        return;
      }
      let data = "";
      time.split("-").forEach((item, index) => {
        if (index === 1) {
          data += item + "月";
        } else if (index === 2) {
          data += flg ? `${item.split(" ")[0]}日` : `${item.split(" ")[0]}日 ${item.split(" ")[1]}`;
        }
      });
      return data;
    },
    applicationTime(data) {
      let newData = new Date().getTime() / 1000;
      let endData =
        new Date(data.replace(new RegExp("-", "gm"), "/")).getTime() / 1000;
      let d,
        h,
        m,
        s = "";
      d = parseInt((endData - newData) / 60 / 60 / 24);
      h = parseInt(((endData - newData) / 60 / 60) % 24);
      m = parseInt(((endData - newData) / 60) % 60);
      s = parseInt((endData - newData) % 60);
      h = h < 10 ? "0" + h : h;
      m = m < 10 ? "0" + m : m;
      s = s < 10 ? "0" + s : s;
      return `${d}天 ${h}:${m}:${s}`;
    },
    TimeInterval(inputTime) {
      var date = new Date(inputTime);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      var second = date.getSeconds();
      minute = minute < 10 ? "0" + minute : minute;
      second = second < 10 ? "0" + second : second;
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + second;
    },
    countdownTime() {
      let _this = this;
      this.timer = setInterval(() => {
        if (_this.details.countdown - parseInt(new Date().getTime()) > 0) {
          --_this.details.countdown;
          _this.details.countdownFlg = true;
        } else {
          _this.details.countdownFlg = false;
          clearInterval(this.timer);
        }

        _this.$forceUpdate();
      }, 1000);
    },
    async getAccountInformation() {
      if (getMemberId() !== "") {
        const { data: res } = await request({
          method: "GET",
          url: "/pcp/PersonalCenter/accountInformation",
          params: {
            id: getMemberId(),
          },
        });
        if (res.code !== 200) return this.$message.error("信息获取失败");
        if (!res.data[0].name) {
          this.typeDialog = "Subject";
        }
        return Promise.resolve();
      }
    },
    handleApplication() {
      setInfoData(request).then((res) => {
        console.log(res)
        console.log(this.typeDialog)
        if (res === "islogin") {
          if (this.typeDialog === "Subject") {
            this.dialogVisiblePermission = true;
          } else {
            this.handleRegisterNow();
          }
        } else {
          this.dialogVisiblePermission = true;
          this.typeDialog = "Login";
        }
      });
    },
    handleRegOrCancle () {
      if (this.isReg) {
        this.cancellationRegistration(this.$route.query.clickId);
        this.isReg = false;
      } else {
        if (this.dataUser && localStorage.getItem('mainName')) {
          this.handleApplication();
          this.isReg = true;
        } else if(!this.dataUser){
          this.dialogVisiblePermission = true;
          this.typeDialog = 'Login';
        } else if (!localStorage.getItem('mainName')) {
          this.dialogVisiblePermission = true;
          this.typeDialog === 'Subject';
        }
      }
    },
    async cancellationRegistration (id) {
      const { data: res } = await request(
        {
          method: 'post',
          url: '/pcp/activity/cancelRegistration',
          data: {
            memberId: getMemberId(),
            activityId: id,
          },
        },
        true
      );
      if (res.code !== 200) return this.$message.error('获取数据异常');
      this.$message({
        message: '已取消报名',
        type: 'success',
      });
    },
    handleToggle() {
      this.dialogVisiblePermission = !this.dialogVisiblePermission;
    },
    handlePermission(type) {
      console.log(type);
      if (type === "Vip") {
        this.dialogVisiblePermission = !this.dialogVisiblePermission;
        this.$message({
          message: "已向客服发送请求成为协会会员通知",
          type: "success",
        });
      } else if (type === "Login") {
        this.$router.push({ name: "login" });
        this.dialogVisiblePermission = !this.dialogVisiblePermission;
      } else if (type === "Subject") {
        this.dialogVisiblePermission = !this.dialogVisiblePermission;
        this.dialogVisible = true;
      }
    },
    testCode(rule, value, callback) {
      if (value.length !== 18) {
        callback(new Error("输入18位统一社会信用代码"));
      } else {
        callback();
      }
    },
    async bindMember(
      area,
      estiblishTime,
      regCapital,
      legalPersonName,
      entityName,
      code
    ) {
      const { data: res } = await request({
        method: "post",
        url: "/pcp/PersonalCenter/newbindmemberone",
        data: {
          area: area,
          estiblishTime: estiblishTime,
          regCapital: regCapital,
          legalPersonName: legalPersonName,
          memberId: getMemberId(),
          entityName: entityName,
          code: code,
          sysType: 1,
        },
      });
      if (res.code !== 200) {
        return this.$message.error("主体名称或信用代码不正确");
      }
      // this.hasBindEntity = true
      this.dialogVisible = !this.dialogVisible;
      this.$message.success("绑定成功");
      const str = JSON.parse(localStorage.getItem("userinfo"));
      str.entityId = res.data.entityId;
      const str2 = JSON.stringify(str);
      localStorage.setItem("userinfo", str2);
      sessionStorage.setItem("userinfo", str2);
      this.getAccountInformation();
      this.typeDialog = ""
    },
    bindEntity() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          if (this.form.type === "0") {
            if (
              this.form.first_code === this.form.creditCode[14] &&
              this.form.second_code === this.form.creditCode[15] &&
              this.form.third_code === this.form.creditCode[16] &&
              this.form.fourth_code === this.form.creditCode[17]
            ) {
              this.bindMember(
                this.form.area,
                this.form.estiblishTime,
                this.form.regCapital,
                this.form.legalPersonName,
                this.form.name,
                this.form.creditCode
              );
            } else {
              this.$message.error("请补全后四位");
            }
          } else {
            if (
              this.form.type === "1" &&
              this.form.codeInput &&
              this.form.codeInput.length === 18
            ) {
              this.bindMember(
                this.form.area,
                this.form.estiblishTime,
                this.form.regCapital,
                this.form.legalPersonName,
                this.form.name,
                this.form.creditCode
              );
            } else {
              this.$message.error("请输入18位社会信用代码");
            }
          }
        }
      });
    },
    querySearch(queryString, cb) {
      request({
        method: "GET",
        url: "/pcp/PersonalCenter/getentitynameandcode",
        params: {
          entityName: queryString,
        },
      })
        .then((res) => {
          if (res.data.code === 200) {
            res.data.data.items.forEach((v) => {
              v.value = v.name;
            });
            let results = [];
            res.data.data.items.map((item) => {
              // results.push(Object.assign({},{value:item.name}))
              results.push(item);
            });
            if (results.length === 0) {
              results = [{ value: "暂无数据" }];
            }
            cb(results);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleSelect(item) {
      this.form.code = item.creditCode.slice(0, 6) + "******";
      this.form.label = "请补全统一社会信用代码";
      this.form.type = "0";
      this.form.creditCode = item.creditCode;
      this.form.area = item.base;
      this.form.estiblishTime = item.estiblishTime;
      this.form.regCapital = item.regCapital;
      this.form.legalPersonName = item.legalPersonName;
      this.form.name = item.name;
    },
  },
};
</script>

<style lang="less" scoped>
.activityDetails {
  background-color: #fff;
  padding: 43px 0px;
  .details {
    margin-top: 40px;
    .details-top {
      margin-bottom: 30px;
      .top-head {
        width: 100%;
        height: 200px;
        border-radius: 4px;
        background-color: rgba(242, 245, 250, 100);
        justify-content: left;
        margin-bottom: 20px;
        > img {
          height: 100%;
          width: 340px;
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
        }
        > div {
          margin-left: 20px;
          flex: 1;
          > div:nth-of-type(1) {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            margin-bottom: 30px;
            > span {
              font-size: 26px;
              color: rgba(0, 0, 0, 0.9);
            }
          }
          > div {
            > span {
              color: rgba(0, 0, 0, 0.6);
              font-size: 16px;
            }
            > i {
              width: 20px;
              height: 20px;
              margin-right: 6px;
              color: #101010;
            }
          }
          > div:nth-of-type(2) {
            margin-bottom: 23px;
          }
        }
      }
      .top-application {
        // width: 100%;
        height: 115px;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 100);
        box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.12);
        padding: 10px 40px 10px 30px;
        > div {
          > span {
            > span {
              margin-left: 18px;
              color: #ff1f1f;
            }
          }
          > div {
            width: 140px;
            height: 46px;
            line-height: 46px;
            border-radius: 8px;
            background-color: rgba(21, 110, 208, 100);
            color: rgba(255, 255, 255, 100);
            font-size: 16px;
            text-align: center;
            margin-left: 22px;
            cursor: pointer;
          }
        }
        > ul {
          li {
            margin-right: 40px;
            > div {
              color: rgba(0, 0, 0, 0.8);
              font-size: 14px;
            }
            > div:nth-of-type(1) {
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    .details-recommend {
      > div {
        color: rgba(16, 16, 16, 100);
        font-size: 20px;
        margin-bottom: 20px;
      }
    }
  }
}
.registed{
  background-color: rgba(97, 119, 154, 0.2) !important;
  color: #555 !important;
}
.width_content {
  line-height: 27px;
}
/deep/.el-dialog__footer {
  text-align: center;
}
/deep/.el-button {
  width: 60%;
  height: 36px;
  border-radius: 3px;
  background-color: rgba(48, 120, 240, 100);
  color: rgba(255, 255, 255, 100);
  font-size: 14px;
  text-align: center;
  font-family: Microsoft Yahei;
}
.bind-btn {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #ffffff;
  border: none;
  width: 320px;
  height: 46px;
  background: #156ed0;
  border-radius: 5px;
  cursor: pointer;
}
.goto-bind {
  margin-right: 10px;
  width: 120px;
  height: 42px;
  border: 1px solid #156ed0;
  border-radius: 5px;
  cursor: pointer;
  background: #fff;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #156ed0;
}
.hasBindEntity {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.information_color {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  opacity: 0.8;
}
.li_card:hover {
  border-bottom: 2px solid #b7cef5;
}
.li_card {
  border-bottom: 1px solid #cccccc;
}
/deep/.el-form-item {
  width: 60%;
}
.form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
/deep/.el-input__inner {
  font-size: 16px;
  // width:150%;
  height: 50px;
  border: 1px solid #cccccc;
  border-radius: 5px;
}
.loading {
  height: 300px;
}
.creditCode {
  display: flex;
  .div-code {
    margin-right: 5px;
    text-align: center;
    /deep/.el-input__inner {
      width: 40px;
      height: 30px;
      font-size: 12px;
    }
  }
}
.none-data {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 340px;
}
.content {
  display: flex;
  justify-content: center;
}
.content .content-left {
  width: 1200px;
}
.content .content-left /deep/img{
  max-width:100%;
}
</style>
<style >
.atooltip {
  max-width: 800px;
}
</style>
